@tailwind base;
@tailwind components;
@tailwind utilities;

/* set color variables */

:root {
  --color-red: #a5141a;
  --color-dark-blue: #405763;
  --color-light-gray: #ecf1f3;
  --color-hover-gray: #c7d4db;
  --color-text-gray: #6b6b6b
}

body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
