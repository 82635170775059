.call-list {
  margin-top: 30px;
  /* grid by 6 cards in a row */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0 30px;
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
}

.call-list .call-list_item {
  background-color: #ededed;
  padding: 10px 18px;
  border-radius: 8px;
  min-width: 20%;
}

.call-list .call-list_item .accept {
  margin-left: 10px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 30px;
  height: 30px;
  background-color: #4caf50;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  padding: 2px 5px;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background-color: #388e3c;
  }
  svg {
    width: 100%;
    height: auto;
  }
}
.call-list .call-list_item .accept:hover {
  background-color: #388e3c;
}
.call-list .call-list_item .accept svg {
  width: 100%;
  height: auto;
}
